<template>
  <div class="signin-bot-main d-flex">
    <v-row>
      <v-col cols="8" class="w-70 mx-auto">
        <div
          class="
            d-flex
            main-height
            flex-column
            align-center
            justify-center
            flex-grow-1
          "
        >
          <img lazy-src="logo" :src="topLogo" class="mb-2" />
          <div class="">
            <h3 class="my-4 text-center mx-auto" style="width: 360px">
              Your gateway to an enhanced experience
            </h3>

            <v-card
              ref="form"
              class="mx-auto"
              :style="
                $vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width:400px'
              "
            >
              <LoadingComponent overlay v-if="pageLoading" />
              <h1 class="text-center pt-4">Sign In</h1>
              <v-form ref="signin" lazy-validation :model="signin">
                <v-card-text class="px-6">
                  <v-text-field
                    height="32"
                    dense
                    outlined
                    label="Username/Email"
                    :rules="[rules.required]"
                    v-model="signin.username"
                  ></v-text-field>
                  <v-text-field
                    height="32"
                    dense
                    v-model="signin.password"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="showPass ? 'text' : 'password'"
                    label="Password"
                    @click:append="showPass = !showPass"
                    v-on:keyup.enter="keyEnter"
                    outlined
                  ></v-text-field>
                  <v-btn color="primary" @click="submitLogin()" block>
                    Sign In
                  </v-btn>
                </v-card-text>
              </v-form>

              <v-divider class="mt-2"></v-divider>
              <div class="pa-4">
                <div
                  @click="signUpListener()"
                  class="pointer text-center mb-2 underline font-weight-bold"
                >
                  Don't have an account? - Sign up
                </div>
                <div
                  @click="goTo('/forgot-password')"
                  class="pointer text-center mb-2 underline font-weight-bold"
                >
                  Forgot Password?
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import topLogoImage from "@/assets/logos/pinoybot-200px.png";
import Strings from "@/helpers/strings";
import Utils from "@/helpers/utils";
import { mapActions } from "vuex";
import LoadingComponent from "@/components/helpers/Loading";

export default {
  name: "signin",
  components: {
    LoadingComponent,
  },
  data() {
    return {
      showPass: false,
      pageLoading: false,

      signin: {
        username: null,
        password: null,
      },

      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => Strings.validEmail(value),
      },
    };
  },

  computed: {
    topLogo() {
      return topLogoImage;
    },
    inviteCode() {
      return _.get(this.$route, "query.code");
    },
    inviteRole() {
      return _.get(this.$route, "query.role");
    },
  },
  watch: {},

  mounted() {
    this.initBroadcast();
  },

  methods: {
    ...mapActions("auth", ["login", "getCurrentUser"]),

    initBroadcast() {
      BroadcastEvent.$on("AUTO_LOGIN_NEW_USER", (val) => {
        this.loginAsUser({
          username: val.username,
          password: val.password,
        });
      });
    },

    goTo(route) {
      this.$router.push(route);
    },
    keyEnter() {
      this.submitLogin();
    },
    validateSignin() {
      return this.$refs["signin"].validate();
    },
    submitLogin() {
      if (!this.validateSignin()) {
        return;
      }
      this.loginAsUser(this.signin);
    },
    loginAsUser(data) {
      const signinData = {
        username: data.username,
        password: data.password,
      };

      if (this.inviteCode) {
        this.$set(signinData, "invite_key", this.inviteCode);
      }
      if (this.inviteRole) {
        this.$set(signinData, "role", this.inviteRole);
      }
      this.pageLoading = true;
      this.login(signinData).then(
        (res) => {
          this.getCurrentUser().then(
            (resCurrentUser) => {
              this.pageLoading = false;
              //accept invitation via signin

              //redirect to playground
              this.goTo("/dashboard/playground", true);
            },
            (err) => {
              this.pageLoading = false;
            }
          );
        },
        async (err) => {
          // globalErrorHandler(err, "Error logging in!");
          this.pageLoading = false;
          await Utils.showMessageDefault({
            position: "right",
            confirmButtonColor: "#4285f3",
            cancelButtonColor: "#ffffffff",
            denyButtonColor: "#a9a9a9",
            confirmButtonText: "SIGN UP",
            cancelButtonText: "CANCEL",
            denyButtonText: "SIGN IN",
            customClass: "login-popup login-page-popup",
            title: null,
            icon: null,
            text: null,
            showDenyButton: true,
            html: "<span>The email or password are wrong. If you have not already signed up for Pinoybot, then Please Sign Up first.</span>",
          }).then(
            (res) => {
              if (res.isConfirmed) {
                if (this.inviteCode && this.inviteRole) {
                  this.$router.push({
                    path: "/signup",
                    query: { role: this.inviteRole, code: this.inviteCode },
                  });
                } else {
                  this.goTo("signup");
                }
              } else if (res.isDenied) {
                this.goTo("sign-in");
                this.signin.username = null;
                this.signin.password = null;
              }
              return true;
            },
            (error) => {
              return false;
            }
          );
        }
      );
    },
    signUpListener() {
      if (this.inviteCode && this.inviteRole) {
        this.$router.push({
          path: "/signup",
          query: { role: this.inviteRole, code: this.inviteCode },
        });
      } else {
        this.goTo("signup");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signin-bot-main {
  background: white;
}
.main-height {
  height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
}
</style>
